<template>
  <v-card>
    <v-toolbar color="primary" class="rounded-0 text-white" height="80px">
      <v-toolbar-title class="ml-3">
        <h2><b>Tanımlar</b> - Vade Farkları</h2>
      </v-toolbar-title>
      <KTTopMenu></KTTopMenu>
    </v-toolbar>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="4" v-for="interest in interestRates" :key="interest.id">
          <v-text-field
            hide-details="auto"
            :label="interest.id + '. Taksit'"
            v-model="interest.ratio"
            placeholder="0.15"
            prefix="%"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-skeleton-loader v-else type="table" :loading="loading" />
    <v-card-actions
      v-permission="{
        permissions: ['interest-rates-update'],
      }"
      class="d-flex justify-content-end"
    >
      <v-btn class="mr-3" color="error" @click="reset"> İptal </v-btn>
      <v-btn class="mr-3" color="primary" @click="save"> Kaydet </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AxiosInstance from "@/core/services/axios.service";
import KTTopMenu from "@/view/layout/header/TopMenu.vue";

export default {
  name: "InterestRateList",
  components: { KTTopMenu },
  data() {
    return {
      interestRates: [],
      loading: true,
    };
  },
  mounted() {
    this.getInterestRates()
      .then((response) => {
        this.interestRates = response.map((interest) => {
          return {
            id: interest.id,
            ratio: interest.ratio * 100,
          };
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getInterestRates() {
      return new Promise((resolve, reject) => {
        AxiosInstance.get("interests")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    saveInterestRates() {
      return new Promise((resolve, reject) => {
        const interestRates = this.interestRates.map((interest) => {
          return {
            id: interest.id,
            ratio: interest.ratio / 100,
          };
        });
        AxiosInstance.put("interests", interestRates)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    save() {
      this.saveInterestRates()
        .then((response) => {
          this.interestRates = Object.assign(
            {},
            response.map((interest) => {
              return {
                id: interest.id,
                ratio: interest.ratio * 100,
              };
            })
          );
          this.$toast.success("Vade farkları kaydedildi.", {
            position: "bottom-right",
            duration: 2000,
          });
        })
        .catch(() => {
          this.$toast.error("Vade farkları kaydedilemedi.", {
            position: "bottom-right",
            duration: 2000,
          });
        });
    },
    reset() {
      this.getInterestRates()
        .then((response) => {
          this.interestRates = response.map((interest) => {
            return {
              id: interest.id,
              ratio: interest.ratio * 100,
            };
          });
        })
        .catch(() => {
          this.$toast.error("Vade farkları getirilirken bir hata olustu.", {
            position: "bottom-right",
            duration: 2000,
          });
        });
    },
  },
};
</script>

<style scoped></style>
